@import 'colors';

.custom-badge{
  .mat-badge-content {
    position: relative;
    top: 5px !important;
    right: auto !important;
    margin-left: 10px;
    width: auto !important;
    padding: 0 3px;
    min-width: 14px;
    height: 20px;
    border-radius: 0;
    max-width: 270px !important;
    font-weight: bold;

    span {
      font-family: 'Montserrat', sans-serif !important;
    }
  }
  &.badge-sm {
    font-size: 12px;
  }
}

.custom-badge-warn {
  @extend .custom-badge;
  .mat-badge-content {
    background-color: #fff3e0;
    color: $orange-color;
  }
}

.custom-badge-danger {
  @extend .custom-badge;
  .mat-badge-content {
    background-color: #feebee;
    color: $red-color;
  }



}
