@import "colors";
.pv-button-secondary .mdc-button--outlined{

    .mdc-button__label{
      color: #263238!important;

  }

}

.mdc-button{
  text-transform: uppercase;
  font-family: 'Montserrat', serif;
  font-size: 14px;
  font-weight: 500;
}
.pv-button-grey.mdc-button {
  --mdc-protected-button-container-color: #F5F5F5;
  background-color: #F5F5F5!important;

}


.pv-button-stroke{
  .mdc-button--outlined{
    color: #0f4169;
  }
}
