@import 'colors';

.mat-mdc-tab-header {
  margin-bottom: 24px;
}
.mat-mdc-tab-label-container {
  border-bottom: 1px solid  #E0E0E0;

  .mdc-tab__text-label {
    padding-right: 10px;
    padding-left: 10px;
    opacity: 1 !important;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
    color: $light-grey-color;
  }


  .mdc-tab--active .mdc-tab__text-label{
    color: $turquoise-color !important;
  }
  .mat-tab-label {
     max-width: 270px !important;
   }
}

.mdc-tab-indicator__content--underline {
  --mdc-tab-indicator-active-indicator-color: #18A1AEFF;
  --mat-tab-header-active-focus-indicator-color:  #18A1AEFF;
  --mat-tab-header-active-hover-indicator-color:  #18A1AEFF;
}


